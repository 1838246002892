<template>
  <div>
    <ion-slides
      :key="slideImagesVideoKey"
      mode="ios"
      pager="false"
      :options="slideOpts"
      @ionSlideDidChange="updatePager"
    >
      <ion-slide v-for="(media, index) in usedMedia" :key="index">
        <video-player
          v-if="media.type === 'video'"
          class="video-player vjs-big-play-centered"
          :src="media.url"
          :poster="media.thumbnailUrl"
          :volume="0.7"
          :height="280"
          @mounted="handleMounted"
        >
          <template v-slot="{ state }">
            <div class="custom-player-controls d-flex align-center justify-center">
              <ion-button class="play-icon" fill="clear" @click="setOpenImages(true, usedMedia)">
                <ion-img v-if="!state.playing" class="play-img" src="/assets/images/play-video.svg" />
              </ion-button>
            </div>
          </template>
        </video-player>
        <div
          v-else
          class="product-image"
          @click="
            media.url && media.url?.length > 1 && isAnyProductImage ? setOpenImages(true, usedMedia) : false
          "
        >
          <td-image
            class="no-image-wrapper"
            :image="media.url"
            :imagePath="imagePath"
            :imageDefault="imageDefault"
            @errorLoadImg="handleErrorImage"
          ></td-image>
        </div>
        <div
          v-if="isProductOos"
          @click="isAnyProductImage ? setOpenImages(true, usedMedia) : false"
          class="oos-area"
        >
          <div class="blend-img"></div>
          <div class="image-off-oss"></div>
          <div class="centered-oos-label">
            <p class="oos">{{ $t('out_of_stock') }}</p>
          </div>
        </div>
      </ion-slide>
    </ion-slides>
    <div v-if="isAnyProductImage" class="pager-number">
      <ion-icon class="ml-1" slot="start" :icon="imagesOutline"></ion-icon>
      <ion-text>{{ activeSlide + 1 + '/' + usedMedia.length }}</ion-text>
    </div>
    <div
      v-if="
        checkVariantName ||
        (getVariantSelected === checkVariantName &&
          isSelectedVariant &&
          skuMedia.length > 0 &&
          isAnyProductImage)
      "
      class="selected-variant"
    >
      <ion-text>{{ checkVariantName }}</ion-text>
    </div>
    <ion-row v-if="isAnyProductImage" class="ion-justify-content-center dot-slides-area">
      <div
        v-for="(slide, index) in usedMedia"
        :key="index"
        class="pager-dot"
        :class="{ 'pager-dot-active': index === activeSlide }"
      />
    </ion-row>

    <!-- Preview images videos -->
    <ion-modal :is-open="isOpenPreviewImages" @didDismiss="setOpenImages(false, [])">
      <PreviewImagesVideo
        :images="imagesData"
        :selectedIndex="activeSlide"
        @close-page="setOpenImages(false, [])"
      />
    </ion-modal>
  </div>
</template>

<script>
import { VideoPlayer } from '@videojs-player/vue';
import { imagesOutline } from 'ionicons/icons';
import 'video.js/dist/video-js.css';
import { computed, defineAsyncComponent, defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'vue-basic-player-example',
  title: 'Basic player (Vue)',
  components: {
    VideoPlayer,
    PreviewImagesVideo: defineAsyncComponent(() => import('./PreviewImagesVideo.vue'))
  },
  props: {
    isProductOos: {
      type: Boolean,
      default: false
    },
    isSingleVariant: {
      type: Boolean,
      default: false
    },
    image: {
      type: String,
      default: ''
    },
    itemsData: {
      type: Object,
      default: () => {}
    },
    imagePath: {
      type: String,
      default: ''
    },
    imageDefault: {
      type: String,
      default: ''
    },
    selectedVariant: {
      type: Array,
      default: () => []
    },
    productMedia: {
      type: Array,
      default: () => []
    },
    skuMedia: {
      type: Array,
      default: () => []
    }
  },
  emits: ['close-preview'],
  setup(props) {
    const getVariantSelected = computed(() => {
      const resultString = props.selectedVariant.join(', ').replace(/"/g, '');
      return resultString;
    });
    const checkVariantName = computed(() => {
      const variantSpecsName = usedMedia.value[activeSlide.value]?.variantName;
      return variantSpecsName;
    });
    const slideImagesVideoKey = ref(0);
    const isSelectedVariant = computed(() => {
      return props.selectedVariant?.length > 0;
    });
    const usedMedia = computed(() => {
      const mediaData = getChooseData();
      return mediaData;
    });

    const incrementSlideImagesVideoKey = () => {
      slideImagesVideoKey.value++;
    };

    const isAnyImagesVideosAttributes = ref([]);
    const isAnyProductImage = ref(false);
    const isErrorImage = ref(false);
    const handleErrorImage = (imageData) => {
      isErrorImage.value =
        imageData === '/assets/images/new-default-product-img.svg' ||
        imageData === '/assets/images/new-default-product-img.png'
          ? true
          : false;
    };

    const getSpecsName = (variantSpecsName) => {
      let resultString = null;
      if (variantSpecsName) {
        const variantNameObject = JSON.parse(variantSpecsName);

        // Extract values to an array
        const variantNameArray = Object.values(variantNameObject);

        resultString = variantNameArray.join(', ').replace(/"/g, '');
      }
      return resultString;
    };

    const getChooseData = () => {
      // combine media of product and skus
      const combineAllMedia = [];

      props.itemsData.attributes.mediaAttributes?.forEach((product) => {
        combineAllMedia.push({
          ...product,
          level: 'Product',
          variantName: null
        });
      });

      props.itemsData.skusData.forEach((skus) => {
        if (skus.attributes && skus.attributes.mediaAttributes) {
          skus.attributes.mediaAttributes.forEach((mediaAttribute) => {
            combineAllMedia.push({
              ...mediaAttribute,
              level: 'Skus',
              variantName: getSpecsName(skus.specs) || null
            });
          });
        }
      });
      incrementSlideImagesVideoKey();
      const baseImage = [
        {
          url: props.image,
          type: 'image',
          isDefault: true,
          thumbnailUrl: props.image,
          level: 'Product',
          variantName: null
        }
      ];

      isAnyImagesVideosAttributes.value = combineAllMedia;
      const chooseData =
        isAnyImagesVideosAttributes.value?.length > 0 ? isAnyImagesVideosAttributes.value : baseImage;
      isAnyProductImage.value = chooseData[0].url && chooseData[0].url?.length > 1 && !isErrorImage.value;
      // Find the item with isDefault and then move it to first index
      const defaultItem = chooseData.find((item) => item.isDefault);

      if (defaultItem) {
        const index = chooseData.indexOf(defaultItem);
        chooseData.splice(index, 1);
        chooseData.unshift(defaultItem);
      }
      return chooseData;
    };
    const player = ref(null);
    const stateVideo = ref(null);
    const indexWithIsDefault = ref(0);
    const slideOpts = {
      initialSlide: indexWithIsDefault.value,
      speed: 300
    };
    const activeSlide = ref(indexWithIsDefault.value !== -1 ? indexWithIsDefault.value : 0);
    const updatePager = (event) => {
      player.value?.pause();
      activeSlide.value = event.srcElement?.swiper?.activeIndex;
    };

    const isOpenPreviewImages = ref(false);
    const imagesData = ref([]);
    const setOpenImages = (state, images) => {
      const urlArray = images.map(({ url, thumbnailUrl, type, level, variantName }) => ({
        url,
        thumbnailUrl,
        type,
        level,
        variantName
      }));
      isOpenPreviewImages.value = state;
      imagesData.value = urlArray;
    };

    const handlePlayVideo = (player, state) => {
      player.value = player;
      stateVideo.value = state;
      state.playing ? previewFullScreenVideo(player) : player.play();
    };
    const previewFullScreenVideo = (player) => {
      setOpenImages(true, usedMedia.value);
      player.pause();
    };
    const handleMounted = (payload) => {
      player.value = payload.player;
    };

    watch(getVariantSelected, (newVariantUpdated) => {
      const selectedIndex = usedMedia.value?.findIndex((item) => item.variantName === newVariantUpdated);
      if (selectedIndex !== -1) {
        slideOpts.initialSlide = selectedIndex;
        activeSlide.value = selectedIndex;
        getChooseData();
      } else {
        slideOpts.initialSlide = 0;
        activeSlide.value = 0;
        getChooseData();
      }
    });

    return {
      player,
      handleMounted,
      slideOpts,
      handleErrorImage,
      getVariantSelected,
      checkVariantName,
      isSelectedVariant,
      slideImagesVideoKey,
      handlePlayVideo,
      updatePager,
      activeSlide,
      isAnyImagesVideosAttributes,
      isAnyProductImage,
      isOpenPreviewImages,
      usedMedia,
      imagesData,
      setOpenImages,
      imagesOutline
    };
  }
});
</script>

<style scoped>
.video-player {
  background-color: #000;
  width: 100%;
}

.dot-slides-area {
  width: 100%;
  background-color: white;
}
.pager-dot {
  width: 6px;
  height: 6px;
  background-color: #9e9e9e;
  border-radius: 50%;
  margin: 0 4px;
}

.pager-dot-active {
  background-color: #212121;
}

.pager-number {
  z-index: 999;
  display: inline-flex;
  height: 28px;
  padding: 6px 8px;
  align-items: center;
  gap: 6px;
  position: absolute;
  right: 16px;
  top: 236px;
  border-radius: 16px;
  background: rgba(33, 33, 33, 0.5);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
  color: white;
  font-size: 14px;
  font-weight: 300;
}

.selected-variant {
  z-index: 999;
  display: inline-flex;
  height: 28px;
  padding: 6px 8px;
  align-items: center;
  gap: 6px;
  position: absolute;
  left: 16px;
  top: 236px;
  border-radius: 16px;
  background: rgba(33, 33, 33, 0.5);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
  color: white;
  font-size: 14px;
  font-weight: 300;
}
.custom-player-controls {
  height: 280px;
  width: 100%;
}

.play-icon {
  height: 100%;
  width: 100%;
  font-size: 44px;
  --color: white;
}

.play-img {
  height: 72px;
  width: 72px;
}
</style>
<style src="@/modules/b2b/views/product-detail/style.scss" lang="scss" scoped></style>
